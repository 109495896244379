import axios, { AxiosError } from 'axios'
import * as URLS from 'config/api/Urls'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import React, { ChangeEvent, MouseEvent, useState } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import { ScrollToSection } from 'src/components/ScrollToSection'
import { sendCDPFormData } from 'src/shared/helpers'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import errorImg from '../../../../pages/ouvidoria/assets/images/img-error.png'
import successImg from '../../../../pages/ouvidoria/assets/images/img-success.png'

import { SpeakOmbudsman } from './style'
import { IFormValuesOmbudsman } from './types'

function verifyLength (value: string) {
  return value.length >= 50
}

function ContactOmbudsman () {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValuesOmbudsman> = useForm<IFormValuesOmbudsman>()
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)

  const [ acceptedTerms, setAcceptedTerms ] = useState(false)
  const [ alreadyOpenDemand, setAlreadyOpenDemand ] = useState(false)

  const openDemand: string[] = []

  function handleFormError (statusCode?: number) {
    const errorList: { [key: string]: () => void } = {
      429: () => {
        setAlreadyOpenDemand(true)
        ScrollToSection({ id: 'form-ouvidoria' })
        sendDatalayerEvent({
          section: 'dobra_03',
          section_name: 'Fale com a Ouvidoria',
          element_name: 'Enviar',
          element_action: 'submit',
        })
      },
      401: () => {
        setAlreadyOpenDemand(true)
        ScrollToSection({ id: 'form-ouvidoria' })
        sendDatalayerEvent({
          section: 'dobra_03',
          section_name: 'Fale com a Ouvidoria',
          element_name: 'Enviar',
          element_action: 'submit',
        })
      },
    }

    const statusCodeAsString = statusCode?.toString()
    const acceptableErrorCodes = Object.keys(errorList)
    const isStatusCodeValid = (
      statusCodeAsString !== undefined &&
      acceptableErrorCodes.includes(statusCodeAsString)
    )

    if (isStatusCodeValid) {
      errorList[statusCodeAsString]()
      return
    }

    ScrollToSection({ id: 'form-ouvidoria' })
    setError(true)
    sendDatalayerEvent({
      section: 'dobra_03',
      section_name: 'Fale com a Ouvidoria',
      element_name: 'Enviar',
      element_action: 'submit',
    })
  }

  async function handleFormSubmit (formData: IFormValuesOmbudsman) {
    await axios.post(`${URLS.API_OUVIDORIA}/case-site-fale-ouvidoria`, [ formData ], {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setSent(true)
        ScrollToSection({ id: 'form-ouvidoria' })
        sendCDPFormData({ formName: 'Formulario ouvidoria', cpf: formData.cpf?.replace(/\D/g, ''), email: formData.email })
        sendDatalayerEvent({
          section: 'dobra_03',
          section_name: 'Fale com a Ouvidoria',
          element_name: 'Enviar',
          element_action: 'submit',
          step: 'success',
        })
      })
      .catch((requestError: AxiosError) => {
        const errorCode = requestError.response?.status
        handleFormError(errorCode)
      })
  }

  const validateFormData = async (data: IFormValuesOmbudsman) => {
    setLoading(true)
    const formData = {
      ...data,
      utm: window.location.href,
    }
    const blockedCPF = openDemand.includes(formData.cpf as string)

    try {
      if (blockedCPF) {
          handleFormError(401)
        } else {
          await handleFormSubmit(formData)
        }
    } catch (e) {
      handleFormError()
    }
  }

  const handleReturn = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setError(false)
    setLoading(false)
    setAlreadyOpenDemand(false)
  }

  function handleCheck (event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAcceptedTerms(inputValue)
  }

  if (sent) {
    return (
      <div className='d-flex flex-column align-items-center h-100 justify-content-center mt-5'>
        <img src={successImg} alt='Imagem de sucesso Inter' />
        <h3 className='fs-24 fs-lg-22 lh-30 text-grayscalle--500 text-center mt-3'>Informações enviadas!</h3>
        <p className='fs-16 lh-19 text-grayscalle--500 text-center mb-0'>Agora, basta aguardar o contato da nossa equipe.</p>
      </div>
    )
  }

  if (error) {
    return (
      <div className='d-flex flex-column align-items-center h-100 justify-content-center mt-5'>
        <img src={errorImg} alt='Moça com celular na mão' />
        <h3 className='fs-24 fs-lg-22 lh-30 text-grayscalle--500 text-center mt-3'>Informações não enviadas</h3>
        <p className='fs-16 lh-19 text-grayscalle--500 text-center'>
          <span className='d-md-block'>No momento não foi possível enviar suas informações.</span> Aguarde alguns instantes, confira seus dados e tente novamente.
        </p>
        <a
          href='#' onClick={handleReturn}
          className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
        >Tentar novamente
        </a>
      </div>
    )
  }

  if (alreadyOpenDemand) {
    return (
      <div className='d-flex flex-column align-items-center h-100 justify-content-center mt-4'>
        <img src={errorImg} alt='Moça com celular na mão' />
        <h3 className='fs-24 fs-lg-22 lh-30 text-grayscalle--500 text-center mt-3'>Olá!</h3>
        <p className='fs-16 lh-19 text-grayscalle--500 text-center'>
          <span className='d-md-block'>Vimos que você já possui uma demanda aberta neste canal,</span>
          <span className='d-md-block'> para mais detalhes sobre o andamento, </span> entre em
          contato com o chat ou telefone da ouvidoria.
        </p>
        <a
          href='#' onClick={handleReturn}
          className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
        >Tentar novamente
        </a>
      </div>
    )
  }

  return (
    <SpeakOmbudsman>
      <form
        className='form-hooks mt-4'
        name='fale-ouvidoria'
        onSubmit={handleSubmit(validateFormData)}
      >
        <div className='body-form'>
          <div className='col-12 col-lg-6 mt-3 px-0'>
            <div className='col-12 pb-4 d-flex flex-column'>
              <label htmlFor='name' className='fs-14 lh-18'>Nome</label>
              <input
                ref={register({
                  required: 'Insira um nome com mais de 5 caracteres',
                  validate: {
                    isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                  },
                })}
                name='name'
                id='name'
                type='text'
                maxLength={100}
                placeholder='Digite o nome completo'
              />
              {errors.name && <p className='fs-12 text-red--base mb-0 text-right'>{errors.name.message}</p>}
            </div>
            <div className='col-12 pb-4 d-flex flex-column'>
              <label htmlFor='cpf' className='fs-14 lh-18'>CPF/CNPJ</label>
              <input
                ref={register({
                  required: 'Insira um CPF ou CNPJ válido',
                  validate: {
                    isCpf: (value: string) => Validations.cpforcnpj(value) || 'CPF ou CNPJ Inválido',
                  },
                })}
                name='cpf'
                id='cpf'
                type='text'
                placeholder='Informe o CPF ou CNPJ'
                onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPFCNPJ(event.currentTarget.value))}
              />
              {errors.cpf && <p className='fs-12 text-red--base pt-1 mb-0 text-right'>{errors.cpf.message}</p>}
            </div>
            <div className='col-12 pb-4 d-flex flex-column'>
              <label htmlFor='email' className='fs-14 lh-18'>E-mail</label>
              <input
                ref={register({
                  required: 'Insira um e-mail válido',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'E-mail inválido',
                  },
                })}
                name='email'
                id='email'
                type='text'
                placeholder='Informe o e-mail'
              />
              {errors.email && <p className='fs-12 text-red--base pt-1 mb-0 text-right'>{errors.email.message}</p>}
            </div>
            <div className='col-12 pb-4 d-flex flex-column'>
              <label htmlFor='phone' className='fs-14 lh-18'>Telefone</label>
              <input
                ref={register({
                  required: 'Insira um telefone válido',
                  pattern: {
                    value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                    message: 'Telefone inválido',
                  },
                })}
                name='phone'
                id='phone'
                type='tel'
                placeholder='DDD+Número'
                maxLength={15}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('phone', Masks.MaskPHONE(event.currentTarget.value))}
              />
              {errors.phone && <p className='fs-12 text-red--base pt-1 mb-0 text-right'>{errors.phone.message}</p>}
            </div>
            <div className='col-12 pb-4 d-flex flex-column'>
              <label htmlFor='protocol' className='fs-14 lh-18'>Protocolo de Atendimento</label>
              <input
                ref={register({
                  required: 'Insira seu protocolo de atendimento',
                })}
                name='protocol'
                id='protocol'
                type='text'
                maxLength={15}
                placeholder='Informe o protocolo de atendimento'
              />
              {errors.protocol && <p className='fs-12 text-red--base pt-1 mb-0 text-right'>{errors.protocol.message}</p>}
            </div>
          </div>
          <div className='col-12 col-lg-6 mt-lg-3 px-0'>
            <div className='col-12 pb-4 d-flex flex-column'>
              <label htmlFor='chanel' className='fs-14 lh-18'>Canal pelo qual entrou em contato</label>
              <span className='select'>
                <select
                  ref={register({
                    required: 'Selecione o canal pelo qual entrou em contato',
                  })}
                  name='chanel'
                  id='chanel'
                >
                  <option value=''>Selecione</option>
                  <option value='Telefone SAC'>Telefone SAC</option>
                  <option value='Chat de atendimento'>Chat de atendimento</option>
                  <option value='Aplicativo'>Aplicativo</option>
                  <option value='Outros'>Outros</option>
                </select>
              </span>
              {errors.chanel && <p className='fs-12 text-red--base mb-0 text-error-select text-right'>{errors.chanel.message}</p>}
            </div>
            <div className='col-12 pb-4 d-flex flex-column'>
              <label htmlFor='reason' className='fs-14 lh-18'>Motivo do Contato</label>
              <span className='select'>
                <select
                  ref={register({
                    required: 'Selecione o motivo do contato',
                  })}
                  name='reason'
                  id='reason'
                >
                  <option value=''>Selecione</option>
                  <option value='Câmbio Pessoa Física'>Câmbio Pessoa Física</option>
                  <option value='Câmbio PJ'>Câmbio PJ</option>
                  <option value='Câmbio MEI'>Câmbio MEI</option>
                  <option value='Cartão Consignado'>Cartão Consignado</option>
                  <option value='Cartão Múltiplo'>Cartão Múltiplo</option>
                  <option value='Conta Digital Pessoa Física'>Conta Digital Pessoa Física</option>
                  <option value='Conta Digital MEI'>Conta Digital MEI</option>
                  <option value='Conta Digital PJ'>Conta Digital PJ</option>
                  <option value='Empréstimo Consignado'>Empréstimo Consignado</option>
                  <option value='Empréstimo Imobiliário'>Empréstimo Imobiliário</option>
                  <option value='Inter Cel'>Inter Cel</option>
                  <option value='Interviagens'>Interviagens</option>
                  <option value='Investimentos'>Investimentos</option>
                  <option value='Marketplace'>Marketplace</option>
                  <option value='Merchant'>Merchant</option>
                  <option value='Privacidade'>Privacidade</option>
                  <option value='Seguros'>Seguros</option>
                  <option value='Shell Box'>Shell Box</option>
                  <option value='Outros'>Outros</option>
                </select>
              </span>
              {errors.reason && <p className='fs-12 text-red--base mb-0 text-error-select text-right'>{errors.reason.message}</p>}
            </div>
            <div className='col-12 pb-4 pb-lg-2 d-flex flex-column'>
              <label htmlFor='message' className='fs-14 lh-18'>Mensagem</label>
              <textarea
                ref={register({
                  required: 'Insira uma mensagem com mais caracteres',
                  validate: {
                    isName: (value: string) => verifyLength(value) || 'Por favor, digite uma mensagem com mais caracteres',
                  },
                })}
                name='message'
                id='message'
                placeholder='Digite sua mensagem'
                minLength={10}
              />
              {errors.message && <p className='fs-12 mt-1 mb-0 text-red--base text-right'>{errors.message.message}</p>}
            </div>
            <div className='col-12 pb-2 col-md-10 d-flex align-items-center'>
              <input
                ref={register()}
                className='formInputCheck mr-2'
                id='authorize'
                name='authorize'
                type='checkbox'
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleCheck(event)}
              />
              <label className='formLabelCheck fs-12 lh-16 text-gray--800 mb-0' htmlFor='receberContato'>
                Autorizo contato do Inter para prestação desse serviço. Dúvidas, acesse nossa
                <a
                  className='fw-700 text-orange--base'
                  title='Acessar Política de Privacidade do Banco Inter'
                  href='https://inter.co/politica-de-privacidade/privacidade'
                  target='_blank' rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      element_action: 'click button',
                      element_name: 'Política de Privacidade',
                      section_name: 'Fale com a Ouvidoria',
                      redirect_url: 'https://inter.co/politica-de-privacidade/privacidade',
                    })
                  }}
                > Política de Privacidade.
                </a>
              </label>
            </div>
            <div className='col-12 text-center mt-md-1'>
              <button
                disabled={!acceptedTerms || loading}
                className='btn btn--block text-white bg-orange--extra rounded-2'
                type='submit'
                title='Enviar'
                data-home-ouvidoria='formulario'
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </form>
    </SpeakOmbudsman>
  )
}

export default ContactOmbudsman
